$font-abel: 'Abel', sans-serif;
$font-assistant: 'Assistant', sans-serif;
$font-lilita: 'Lilita One', cursive;

body {
  margin: 0;
  font-family: $font-assistant;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  color: $color-white;
  background-color: $color-grey-dark;
  line-height: 20px;
  font-size: 18px;
}

html,
#root,
.App {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}

input,
button,
select {
  font-family: $font-assistant;
  line-height: 20px;
  font-size: 18px;
}

a {
  text-decoration: none;
}

input,
select {
  &:focus {
    outline: none;
  }
}
