.pick-team {
  width: 300px;
  margin: 100px auto;
  input {
    padding: 5px 7px;
    text-align: center;
    font-size: 22px;
  }
  select {
    margin-bottom: 20px;
    width: 100px;
    height: 38px;
    padding: 5px 7px;
  }
}
