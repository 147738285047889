.show-teams {
  .team-wrapper {
    width: 300px;
    margin: 20px auto;
  }
  ul, li {
    padding: 0;
    text-align: center;
  }
  li {
    list-style-type: none;
    margin-bottom: 5px;
  }
  .spymaster-player-icon {
    width: 17px;
    height: 17px;
    margin-right: 20px;
  }
}
