$color-white: #fff;
$color-black: #000000;
$color-grey-very-light: #DFDEDE;
$color-grey-light: #6B6E70;
$color-grey: #686868;
$color-grey-dark: #3C3C3C;
$color-grey-very-dark: #171717;
$color-black-90: rgba(0,0,0,0.9);

$color-blue: #2E9CCA;
$color-red: #F76C6C;
