.game-page {
  text-align: center;
  padding: 20px 0;
  position: relative;
  .game-id {
    font-size:24px;
  }
}
.cluemaster-button,
.player-button {
  border: none;
  color: $color-white;
  height: 38px;
  padding: 2px 5px;
  font-size:14px;
  @include breakpoint($screen-large) {
    padding: 5px 15px;
    font-size: 18px;
  }
}

.cluemaster-button,
.player-button {
  @include breakpoint($screen-large) {
    width: 120px;
  }
}
.player-button {
  border-radius: 3px 0 0 3px;
  @include breakpoint($screen-large) {
    border-radius: 8px 0 0 8px;
  }
}
.cluemaster-button {
  border-radius: 0 3px 3px 0;
  @include breakpoint($screen-large) {
    border-radius: 0 8px 8px 0;
  }
}
.game-page-top,
.game-page-bottom,
.board {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 5px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @include breakpoint($screen-large) {
    width: 740px;
    padding: 20px 0px;
  }
}
.game-page-top,
.game-page-bottom {
  display: flex;
  justify-content: space-between;
}
.turn-indicator,
.timer {
  margin-bottom: 10px;
}
.game-page-left {
  text-align: left;
}
.score {
  margin: 0px 0 20px 0;
  @include breakpoint($screen-large) {
    margin: 5px 0 20px 0;
  }
}
.timer {
  text-align: right;
}
.turn-indicator,
.score {
  font-size: 16px;
  @include breakpoint($screen-large) {
    font-size: 22px;
  }
}
.turn-indicator {
  &.game-over {
    padding: 10px;
  }
}
.game-page-bottom {
  .page-page-left {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint($screen-large) {
      flex-wrap: nowrap;
    }
  }
}
.team-info {
  margin-top: 10px;
}
.see-teams-button,
.change-team-button {
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
  padding: 0;
}
