.card {
  font-size: 9px;
  line-height: 9px;
  box-sizing: border-box;
  width:60px;
  width: calc(20% - 3.3px);
  height:50px;
  color: $color-white;
  border: none;
  background-color: $color-grey;
  margin: 2px;
  word-wrap: break-word;
  font-family: $font-abel;
  &:nth-child(5n) {
    margin-right: 0px;
  }
  &:nth-child(5n+1) {
    margin-left: 0px;
  }
  &.disabled,
  &.guessed {
    cursor:default;
  }
  @include breakpoint($screen-large) {
    width: 140px;
    height: 80px;
    font-size: 16px;
    line-height: 18px;
    margin: 5px;
  }
  &:not(.disabled) {
    &:hover {
      transform: scale(1.025);
    }
  }
}
.guessed {
  color: $color-black;
  background-color: $color-grey-very-light;
}
.cluemaster {
  background-color: $color-grey;
  &:hover.guessed,
  &.guessed {
    color: $color-white;
    filter: brightness(40%);
  }
}
.assassin {
  background-color:$color-black;
  color:$color-white;
  background-image: url('../images/bomb.png');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center bottom 6px;
  @include breakpoint($screen-large) {
    background-size: 20px;
    background-position: right 10px bottom 10px;
  }
}
