.instruction {
  position: absolute;
  top: 0;
  height: 100%;
  left: -100%;
  width: 100%;
  z-index:2;
  transition: all 1s ease-in-out;
  border-right: 5px solid $color-blue;
  box-sizing: border-box;
  overflow-y:auto;
  &.expanded {
    left: 0;
    .instruction-wrapper {
      display: block;
    }
  }
  background-image: url('../images/background.png');
  @include breakpoint($screen-large) {
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.instruction-wrapper {
  width: 90%;
  margin: 0 auto;
  @include breakpoint($screen-large) {
    width: 70%;
    max-width: 600px;
  }
  h2 {
    margin: 25px 0;
  }
  p {
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .spymaster-player-icon {
    width: 15px;
    height: auto;
  }
}
button.big-button {
  &.instruction-button-open {
    padding: 25px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  &.instruction-button-close {
    margin: 20px 0;
  }
}
