.overlay {
  box-sizing: border-box;
  position:absolute;
  top:0;
  width:100%;
  height:100%;
  background: $color-black-90;
  z-index: 10;
  left: -100%;
  transition: all 0.5s ease-in-out;
  border-right: 5px solid $color-red;
  &.expanded {
    left: 0;
  }
}
