.red {
  color: $color-red;
}

.blue {
  color: $color-blue;
}

.white {
  color: $color-white;
}

.grey-light {
  color: $color-grey-light;
}

.background-red {
  background-color: $color-red;
}

.background-blue {
  background-color: $color-blue;
}

.background-white {
  background-color: $color-white;
}

.background-grey-dark {
  background-color: $color-grey-dark;
}

.background-grey-very-dark {
  background-color: $color-grey-very-dark;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}
