button {
  cursor: pointer;
  &:hover {
    filter: brightness(110%);
  }
  &:active,
  &:focus {
    outline: none;
  }
  &.big-button {
    padding:10px 15px;
    border: none;
    line-height: 20px;
    font-size: 18px;
    &.responsive-button {
      padding: 2px 5px;
      font-size:14px;
      @include breakpoint($screen-large) {
        padding:10px 15px;
        font-size: 18px;
      }
    }
  }
}
