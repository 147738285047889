.login-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-image: url('../images/background.png');
  overflow-y: hidden;
  .logo {
    margin-bottom: 20px;
  }
  @include breakpoint($screen-large) {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.game-id-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  input[type="text"] {
    border: none;
    height: 31px;
    font-size: 22px;
    line-height: 25px;
    padding: 3px 8px;
    color: $color-red;
    margin-bottom: 15px;
    width: 90%;
    text-align: center;
    @include breakpoint($screen-large) {
      margin: 0px 10px 0 0;
      width: 550px;
    }
  }
  input[type="submit"] {
    border-radius: none;
    background: none;
    height: 37px;
    width: 70px;
    font-size: 18px;
    border: 1px solid $color-blue;
    cursor: pointer;
    &:hover {
      background-color: $color-blue;
      text-shadow: 1px 1px 2px $color-grey-dark;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  @include breakpoint($screen-large) {
    flex-wrap: nowrap;
  }
}
.login-prompt {
  font-size: 22px;
  line-height: 25px;
  margin: 20px 0;
}
.timer-input {
  padding: 20px;
  button {
    &:hover {
      text-shadow: 1px 1px 2px $color-grey-dark;
    }
  }
}
.time-selection {
  margin: 20px 0;
  select {
    background: none;
    border-radius: none;
    border: 1px solid $color-blue;
    background-color: $color-grey-very-dark;
    color: $color-white;
    margin-left: 10px;
    height: 38px;
    padding: 3px 8px;
  }
  option {
    border: 1px solid $color-blue;
  }
}
